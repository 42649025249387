.media {
  object-fit: cover;
  width: 200px;
  margin-top: 16px;
}

.card {
  margin-top: 16px;
}

.headerSearch {
  width: 100%;
}
