.root {
  display: grid;
  place-items: center;
  height: "100vh";
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin: "8px";
  object-fit: contain;
  height: "100px";
  width: "200px";
}
