.center {
  display: flex;
  position: absolute;
  top: 10%;
  left: 50%;
}

#original {
  opacity: 0.2;
}
#step1 {
  visibility: hidden;
  animation: showStepOne 0.4s linear infinite;
}
#step2 {
  visibility: hidden;
  animation: showStepTwo 0.4s linear infinite;
}
#step3 {
  visibility: hidden;
  animation: showStepThree 0.4s linear infinite;
}

@keyframes showStepOne {
  0% {
    visibility: visible;
    scale: 1px;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: hidden;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}
@keyframes showStepTwo {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: visible;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}
@keyframes showStepThree {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: hidden;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}
