.center {
  display: flex;
  position: absolute;
  top: 10%;
  left: 50%;
  --valueMax: 1;
  --valueMin: 0.7;
}
#arrow {
  opacity: var(--valueMin);
}
#Vector3 {
  animation: showStep1 1s linear infinite;
}
#Vector2 {
  animation: showStep2 1s linear infinite;
}
#Vector1 {
  animation: showStep3 1s linear infinite;
}

@keyframes showStep1 {
  0% {
    opacity: var(--valueMax);
  }

  20% {
    opacity: var(--valueMin);
  }

  40% {
    opacity: var(--valueMin);
  }

  60% {
    opacity: var(--valueMin);
  }

  80% {
    opacity: var(--valueMin);
  }

  100% {
    opacity: var(--valueMin);
  }
}
@keyframes showStep2 {
  0% {
    opacity: var(--valueMin);
  }

  20% {
    opacity: var(--valueMin);
  }

  40% {
    opacity: var(--valueMax);
  }

  60% {
    opacity: var(--valueMin);
  }

  80% {
    opacity: var(--valueMin);
  }

  100% {
    opacity: var(--valueMin);
  }
}
@keyframes showStep3 {
  0% {
    opacity: var(--valueMin);
  }

  20% {
    opacity: var(--valueMin);
  }

  40% {
    opacity: var(--valueMin);
  }

  60% {
    opacity: var(--valueMin);
  }

  80% {
    opacity: var(--valueMax);
  }

  100% {
    opacity: var(--valueMin);
  }
}
