.root {
  overflow: hidden;
}
.sectionDatePicker {
  padding-bottom: 24px;
}
.datePicker_container {
  display: flex;
  justify-content: center;
}
.previewImage_container {
  display: flex;
  justify-content: center;
  position: relative;
}
.previewImage_containerCard {
  height: 270px;
  width: 200px;
  position: relative;
}
.previewImage_containerCard_2 {
  position: relative;
  height: 100%;
  width: 100%;
}
.imageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: contain;
}
.bar_section {
  /* margin-top: 24px; */
  width: 100%;
  position: relative;
  height: 50px;
}
.barContainer {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: var(--barLineContainerColor);
  pointer-events: none;
}
.verticalLine {
  position: absolute;
  height: 50px;
  width: 2px;
  background-color: var(--barLineColor);
  top: 0%;
  pointer-events: none;
}
/* /////////////////////////////// */

.previewImage_containerCard_dateContainer {
  position: absolute;
  top: 8px;
  left: 8px;
  color: white;
  padding: 8px;
  background-color: rgb(56, 54, 54, 0.7);
  border-radius: 8px;
}
.bar_numbersContainer {
  /* margin-top: 24px; */
  /* position: relative; */
  pointer-events: none;
}
.miniVerticalLine {
  position: absolute;
  height: 10px;
  width: 1px;
  background-color: var(--barLineColor);
  pointer-events: none;
}
.timelineStartDate {
  position: absolute;
  top: -5px;
  left: 0;
  transform: rotateZ(-45deg);
}
.timelineEndDate {
  position: absolute;
  top: -5px;
  right: -3px;
  transform: rotateZ(45deg);
}
