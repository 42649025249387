/* eslint-disable */
@charset "UTF-8";
.no-select,
.video-js {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.video-js .vjs-limit a,
.video-js .vjs-logo-bar a,
.vjs-audio-info a,
.vjs-info a {
  text-decoration: none;
}
.no-select,
.video-js,
.video-js .vjs-progress-holder {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}
.video-js .vjs-related .rel-item label,
.vjs-audio-info .vjs-audio-item,
.vjs-chapter {
  white-space: nowrap;
  text-overflow: ellipsis;
}
@font-face {
  font-family: nuevo;
  src: url("font/nuevo.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
*,
:after,
:before {
  box-sizing: border-box;
}
::-moz-focus-inner,
:active,
:focus {
  outline: 0;
  border: 0;
  -moz-outline-style: none;
}
:focus {
  outline: 0 !important;
  border: 0;
  -moz-outline-style: none;
}
.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  line-height: 1;
  outline: 0 !important;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif !important;
  user-select: none;
  -webkit-tap-highlight-color: transparent !important;
  overflow: hidden;
}
.video-js .vjs-volume-menu-button,
.vjs-overflow {
  overflow: visible;
}
.video-js .vjs-auto-mute {
  top: 10px;
  right: 10px;
  position: absolute;
  background: #eee;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  z-index: 6;
}
.video-js .vjs-auto-mute:before {
  content: "\f104";
  font-family: nuevo;
  color: #222;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vjs-chrome {
  width: 13px;
  margin: 0;
  padding: 0;
  height: 0;
}
.video-js .disabled {
  pointer-events: none;
}
.vjs-ad-hidden {
  display: none !important;
}
.vjs-brightness {
  position: absolute;
  height: 35%;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0;
  left: 0;
  width: 50px;
  max-height: 250px;
}
.vjs-ad-playing .vjs-brightness,
.vjs-ended .vjs-brightness {
  opacity: 0 !important;
  pointer-events: none !important;
}
.vjs-480 .vjs-brightness {
  width: 40px;
  display: none;
}
.vjs-touch-active .vjs-brightness {
  opacity: 1;
  pointer-events: auto;
}
.vjs-brightness:before {
  font-family: nuevo;
  content: "\ea48";
  font-style: normal;
  font-size: 20px;
  color: #fff;
  top: -30px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  text-shadow: 1px 1px 1px #000;
}
.vjs-brightness .vjs-brightness-bar {
  height: 100%;
  width: 100%;
}
.vjs-brightness .vjs-brightness-bar .bar {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #888;
  transform: translateX(-50%);
  left: 50%;
}
.vjs-brightness .vjs-brightness-bar .bar-level {
  width: 4px;
  height: 50%;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  transform: translateX(-50%);
  left: 50%;
}
.vjs-filters-on .vjs-big-button,
.vjs-filters-on .vjs-big-play-button,
.vjs-filters-on .vjs-brightness,
.vjs-filters-on .vjs-control-bar {
  display: none !important;
}
.video-js .vjs-filters {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  width: 80%;
  max-width: 320px;
}
.vjs-ended .vjs-filters,
.vjss-ad-playing .vjs-filters {
  display: none;
}
.video-js .vjs-filters .vjs-filter-body {
  margin-left: 40px;
  display: table;
  height: 44px;
  position: relative;
  width: 85%;
  font-family: nuevo;
  font-style: normal;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.vjs-filters-icon:before {
  content: "\ea4b";
  font-family: nuevo;
  font-size: 15px;
}
.video-js .vjs-filter-brightness:before {
  content: "\ea48";
  position: absolute;
  left: -40px;
  top: 11px;
}
.video-js .vjs-filter-saturation:before {
  content: "\ea49";
  position: absolute;
  left: -40px;
  top: 11px;
}
.video-js .vjs-filter-contrast:before {
  content: "\ea4a";
  position: absolute;
  left: -40px;
  top: 11px;
}
.video-js .vjs-filters .vjs-filter-bar {
  background: #888;
  height: 4px;
  width: 100%;
  margin-top: 20px;
  position: relative;
  pointer-events: none;
}
.video-js .vjs-filters .vjs-filter-bar .vjs-filter-level {
  width: 50%;
  height: 100%;
  background: #fff;
  pointer-events: none;
}
.video-js .vjs-filters .filter-btn {
  float: right;
  color: #fff;
  display: inline-block;
  padding: 2px 5px;
  cursor: pointer;
  margin-top: 4px;
  font-size: 12px;
  border: 1px solid transparent;
}
.video-js .vjs-filters .filter-reset {
  float: left;
}
.vjs-has-mouse .vjs-filters .filter-btn:hover {
  border: 1px solid #e5e5e5;
}
.video-js .vjs-filters .filter-tip {
  position: absolute;
  top: -22px;
  font-size: 12px;
  font-family: Arial;
  opacity: 0;
  pointer-events: none;
}
.video-js .vjs-filters .tip-show {
  opacity: 1;
}
.no-select {
  user-select: none;
}
.vjs-bar-height {
  height: 40px;
  position: absolute;
  top: 0;
  background: 0 0;
}
.vjs-airplay-button .vjs-icon-placeholder:before {
  font-family: nuevo;
  content: "\e90c";
}
.vjs-control-text,
.vjs-icon-placeholder,
.vjs-no-pointer {
  pointer-events: none;
}
.vjs-overflow {
  z-index: 10000001;
}
.vjs-playlist-button,
.vjs-vplaylist {
  right: 0;
  position: absolute;
  top: 0;
  z-index: 4;
}
.vjs-spacer {
  display: none;
}
.video-js.vjs-pip .vjs-pip-control .vjs-icon-placeholder:before,
.vjs-icon-pip-exit:before {
  font-family: nuevo;
  content: "\e900";
  font-size: 24px;
}
.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder,
.video-js .vjs-pip .vjs-icon-placeholder,
.vjs-icon-picture-in-picture-enter,
.vjs-icon-pip-enter {
  font-family: nuevo;
  font-weight: 400;
  font-style: normal;
}
.video-js .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.video-js .vjs-pip-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-enter:before,
.vjs-icon-pip-enter:before {
  font-family: nuevo;
  content: "\f10a";
  font-size: 24px;
}
.video-js.vjs-picture-in-picture .vjs-picture-in-picture-control .vjs-icon-placeholder,
.video-js.vjs-picture-in-picture .vjs-pip .vjs-icon-placeholder,
.vjs-icon-picture-in-picture-exit,
.vjs-icon-pip-exit {
  font-family: nuevo;
  font-weight: 400;
  font-style: normal;
}
.video-js.vjs-picture-in-picture .vjs-picture-in-picture-control .vjs-icon-placeholder:before,
.video-js.vjs-picture-in-picture .vjs-pip-control .vjs-icon-placeholder:before,
.vjs-icon-picture-in-picture-exit:before,
.vjs-icon-pip-exit:before {
  font-family: nuevo;
  content: "\e900";
  font-size: 20px;
}
.video-js .vjs-picture-in-picture-control,
.video-js .vjs-pip-control {
  cursor: pointer;
  flex: none;
}
.vjs-playlist-button {
  font-family: nuevo;
  font-size: 25px;
  padding: 10px;
  opacity: 0.85;
  cursor: pointer;
}
.vjs-playlist-button:before {
  content: "\f00a";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
}
.vjs-vplaylist {
  width: 0;
  background: #282828;
  overflow: hidden;
  padding-bottom: 40px;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.vjs-vplaylist-first {
  z-index: 10;
}
.vjs-vplaylist-show {
  width: 250px;
}
.vjs-vplaylist-horizontal {
  position: relative;
  width: 100%;
  color: #fff;
  padding-bottom: 0;
}
.vjs-vplaylist .vjs-head {
  background: #000;
  border-bottom: solid 1px #484848;
  width: 100%;
  padding: 0 5px 0 10px;
  font-size: 13px;
  line-height: 38px;
  text-align: left;
}
.vjs-vplaylist .vjs-head .vjs-back {
  float: right;
  width: 20px;
  height: 28px;
  cursor: pointer;
  margin: 1px;
}
.vjs-vplaylist .vjs-head .vjs-back i {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.vjs-vplaylist .vjs-head .vjs-back .vdown {
  -webkit-transform: rotate(335deg);
  transform: rotate(225deg);
  -webki-transform-origin: center 8px;
  transform-origin: center 8px;
}
.vjs-vplaylist .vjs-head .vjs-back .vup {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.vjs-vplaylist .vjs-vlist {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #666 #444;
}
.vjs-vplaylist .vjs-list-max {
  height: 250px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.vjs-vplaylist .vjs-list-min {
  height: 0 !important;
}
.vjs-vplaylist .vjs-vlist .vjs-item {
  padding: 8px;
  border-bottom: solid 1px #444;
  cursor: pointer;
  display: table;
  width: 100%;
  text-align: left;
}
.vjs-vplaylist .vjs-vlist::-webkit-scrollbar {
  width: 8px;
  background: #444;
}
.vjs-vplaylist .vjs-vlist::-webkit-scrollbar-thumb {
  background: #666;
}
.vjs-vplaylist .vjs-vlist .vjs-last {
  height: 80px;
  width: 100%;
}
.vjs-vplaylist .vjs-vlist .vjs-active-item {
  background-color: #444;
}
.vjs-vplaylist .vjs-vlist .vjs-active-item p {
  color: #fff !important;
}
.vjs-vplaylist .vjs-vlist .vjs-active-item .vjs-desc {
  color: #ccc !important;
}
.vjs-vplaylist .vjs-vlist .vjs-item .vjs-tmb {
  width: 80px;
  height: 45px;
  display: inline-block;
  background-color: #000;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 1px solid #444;
  float: left;
  margin-right: 10px;
  pointer-events: none;
}
.vjs-vplaylist .vjs-vlist .vjs-item:hover p {
  color: #fff;
}
.vjs-vplaylist .vjs-vlist .vjs-item p {
  font-size: 12px;
  color: #ccc;
  margin: 0;
  pointer-events: none;
  min-height: 30px;
  overflow: hidden;
  padding-right: 6px;
}
.vjs-vplaylist-horizontal .vjs-vlist .vjs-item p {
  height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 0;
}
.vjs-vplaylist-horizontal .vjs-desc {
  color: #999 !important;
}
.vjs-vplaylist .vjs-vlist .vjs-item span {
  font-size: 12px;
  margin-top: 3px;
  pointer-events: none;
}
.video-js:-moz-full-screen {
  position: absolute;
}
.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.video-js .vjs-block {
  display: block !important;
}
.vjs-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch;
}
.vjs-menu .vjs-menu-title {
  display: none;
}
.vjs-marker {
  position: absolute;
  top: 15px;
  padding: 5px;
  margin-left: -7px;
}
.vjs-marker-inn {
  width: 4px;
  height: 2px;
  background-color: #fff;
}
.vjs-chapter {
  position: absolute;
  font-size: 12px;
  background: #eee;
  color: #000;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 4px;
  bottom: 45px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  overflow: hidden;
}
.vjs-chapter-mix {
  background: 0 0;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.video-js .vjs-grid {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 97;
  text-align: center;
}
.video-js .vjs-grid p {
  margin: 0;
  line-height: 38px;
  background: #333;
  font-size: 16px;
}
.video-js .vjs-grid .vjs-close-btn {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: 10;
  font-size: 30px;
}
.video-js .vjs-related {
  position: absolute;
  overflow: hidden;
  width: 80%;
  left: 10%;
  max-width: 800px;
}
.video-js .vjs-related .rel-block {
  position: absolute;
  top: 0;
  left: 0;
}
.video-js .vjs-related .rel-anim {
  -webkit-transition: left 0.5 ease;
  transition: left 0.5s ease;
}
.video-js .vjs-related .rel-block .rel-parent {
  position: absolute;
  padding: 2px;
}
.video-js .vjs-related .rel-item {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.video-js .vjs-related .rel-item:hover .rel-bg {
  opacity: 0.7;
}
.video-js .vjs-related .rel-item a {
  width: 100%;
  height: 100%;
  display: block;
}
.video-js .vjs-related .rel-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 1;
  display: block;
}
.video-js .vjs-related .rel-item label {
  width: 90%;
  text-align: left;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  max-height: 2.5em;
  overflow: hidden;
  text-shadow: 1px 1px 1px #000;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  margin: 3% 5%;
  cursor: pointer;
}
.video-js .vjs-related .rel-block .rel-item i {
  position: absolute;
  color: #fff;
  bottom: 0;
  right: 0;
  padding: 4px;
  font-style: normal;
  background: rgba(0, 0, 0, 0.5);
  margin: 2px;
  font-size: 12px;
}
.vjs-grid .vjs-arrow,
.vjs-playlist-nav {
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  cursor: pointer;
  display: table;
  width: 27px;
}
.vjs-disabled {
  cursor: none;
}
.vjs-grid .vjs-disabled,
.vjs-playlist-nav .vjs-disabled {
  opacity: 0.35;
  cursor: none;
}
.vjs-context-menu {
  background: #fff;
  display: inline-block;
  color: #222;
  border: 1px solid #dadce0;
  position: absolute;
  white-space: nowrap;
  z-index: 99;
  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
}
.vjs-context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.vjs-context-menu li {
  padding: 5px 20px;
  width: 100%;
  cursor: pointer;
}
.vjs-context-menu li:hover {
  background: #e5e5e5;
}
.vjs-context-menu .link {
  border-top: solid 1px #dadce0;
  margin-top: 6px;
}
.vjs-context-menu li a {
  font-size: 10px;
  font-weight: 400;
  color: #222;
  text-decoration: none;
}
.vjs-context-menu li:hover a {
  font-size: 10px;
}
.vjs-playlist-nav {
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
}
.vjs-nav-prev {
  left: 10px;
  display: none;
  padding: 10px 0 10px 10px;
}
.vjs-nav-next {
  right: 10px;
  display: none;
  padding: 10px 10px 10px 0;
}
.vjs-grid .vjs-arrow .vjs-next,
.vjs-grid .vjs-arrow .vjs-prev,
.vjs-playlist-nav .vjs-next,
.vjs-playlist-nav .vjs-prev {
  width: 27px;
  height: 27px;
  z-index: 10;
  cursor: pointer;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.video-js .vjs-tech,
.video-js.vjs-fill {
  width: 100%;
  height: 100%;
}
.vjs-grid .vjs-arrow .vjs-prev,
.vjs-playlist-nav .vjs-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.vjs-grid .vjs-arrow .vjs-next,
.vjs-playlist-nav .vjs-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.vjs-playlist-nav .vjs-next {
  padding: 0;
}
.vjs-has-started.video-js.vjs-user-active .vjs-nav-prev,
.vjs-has-started.vjs-user-active .vjs-nav-next {
  display: table;
}
.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-fluid {
  height: 0;
  max-width: 100%;
  width: 100%;
}
.video-js.vjs-16-9 {
  padding-top: 56.25%;
}
.video-js.vjs-4-3 {
  padding-top: 75%;
}
.video-js .vjs-tech {
  left: 0;
  position: absolute;
  top: 0;
  display: inline-block;
}
body.vjs-full-window {
  height: 100%;
  margin: 0;
  padding: 0;
}
.vjs-full-window .video-js.vjs-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999999;
}
.video-js.vjs-fullscreen {
  height: 100% !important;
  padding-top: 0 !important;
  width: 100% !important;
}
.video-js.vjs-fullscreen.vjs-user-inactive {
  cursor: none;
}
.vjs-close-btn:before {
  content: "\f115";
}
.video-js .vjs-close-btn {
  font-family: nuevo;
  color: #fff;
  font-size: 3em;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}
.video-js .vjs-zoom-help {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: table;
  text-align: center;
  padding: 10px;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 20px;
  font-size: 12px;
  max-width: 95%;
  min-width: 60%;
}
.vjs-zoom-help .zoom-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 10px;
  font-size: 20px;
  cursor: pointer;
}
.video-js .vjs-zoom-parent {
  position: absolute;
  left: 0;
  top: 0;
  height: 30px;
  line-height: 30px;
  background: #222;
}
.vjs-zoom-parent .vjs-reset-zoom {
  float: left;
  padding: 0 5px;
}
.vjs-zoom-parent .vjs-reset-info:before {
  content: "\f128";
}
.vjs-zoom-parent .vjs-reset-cancel:before {
  content: "\f00d";
}
.vjs-zoom-parent .vjs-reset-center:before {
  content: "\e9b3";
}
.vjs-reset-cancel,
.vjs-reset-center,
.vjs-reset-info {
  padding: 0 7px;
  color: #fff;
  font-family: nuevo;
  float: left;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  text-shadow: 1px 1px 1px #000;
}
.video-js .vjs-logo-bar,
.video-js .vjs-logo-bar a {
  display: table-cell;
  vertical-align: middle;
  height: 40px;
}
.video-js .vjs-button:focus,
.video-js .vjs-button:hover,
.video-js .vjs-menu-item:focus,
.video-js .vjs-menu-item:hover,
.video-js button:focus,
.video-js button:hover {
  outline: 0;
}
.video-js .vjs-logo-bar {
  margin: 0;
  padding: 0 2px 0 4px;
}
.video-js .vjs-logo-bar img {
  max-height: 19px;
  width: auto;
  display: block;
}
.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-ad-playing) .vjs-logo-min {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.video-js .vjs-logo {
  position: absolute;
  visibility: visible;
  opacity: 1;
  z-index: 97;
}
.video-js .vjs-logo-bottom {
  bottom: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.vjs-has-started.vjs-paused .vjs-logo-bottom,
.vjs-has-started.vjs-user-active .vjs-logo-bottom {
  bottom: 50px;
}
.video-js .vjs-hidden,
.video-js .vjs-hidden-plus {
  display: none !important;
}
.video-js .vjs-remaining-time-display {
  display: none;
  margin-left: 5px;
}
.video-js .vjs-button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  cursor: pointer;
  width: 34px;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.vjs-control-bar .vjs-control:hover .vjs-control-text {
  visibility: visible;
  opacity: 1;
  top: -40px;
}
.video-js .vjs-control-text {
  border: 0;
  margin: 0;
  position: absolute;
  z-index: 2;
}
.vjs-control-text {
  display: none;
}
.vjs-has-mouse .vjs-control-text {
  display: block;
  border: 0;
  clip: auto;
  top: -10px;
  background-color: #eee;
  color: #000;
  padding: 0 5px !important;
  line-height: 22px;
  font-size: 11px !important;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  width: auto;
  z-index: 2;
  text-shadow: none !important;
  font-family: Arial, sans-serif;
}
.vjs-control-text:after {
  content: "";
  display: block;
  background-color: #eee;
  top: 100%;
  position: absolute;
  left: 50%;
  height: 14px;
  width: 14px;
  border-radius: 1px;
  -webkit-transform-origin: 75% 50%;
  transform-origin: 75% 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}
.vjs-play-control .vjs-control-text {
  left: 5px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.vjs-play-control .vjs-control-text:after {
  left: 5px;
  -webkit-transform: translate(0, -50%) rotate(45deg);
  transform: translate(0, -50%) rotate(45deg);
}
.vjs-fullscreen-control .vjs-control-text {
  left: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  right: 5px;
}
.vjs-fullscreen-control .vjs-control-text:after {
  left: auto;
  right: 6px;
  -webkit-transform: translate(0, -50%) rotate(45deg);
  transform: translate(0, -50%) rotate(45deg);
}
.vjs-load-progress .vjs-control-text,
.vjs-menu .vjs-control-text,
.vjs-progress-control .vjs-control-text,
.vjs-time-control .vjs-control-text,
.vjs-volume-level .vjs-control-text,
.vjs-volume-tooltip {
  display: none;
}
.vjs-volume-bar .vjs-mouse-display {
  position: absolute;
  color: #000;
  font-size: 12px;
  margin-left: -9px;
  top: -17px;
  opacity: 0;
}
.vjs-volume-bar:active .vjs-mouse-display,
.vjs-volume-bar:hover .vjs-mouse-display {
  opacity: 1;
}
.video-js .vjs-control-bar {
  background-color: #eee;
  bottom: 0;
  height: 40px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  display: none;
}
.vjs-has-started .vjs-control-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.vjs-waiting:not(.vjs-seeking) .vjs-background-bar,
.vjs-waiting:not(.vjs-seeking) .vjs-control-bar {
  display: none;
}
.vjs-ad-playing .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}
.video-js .vjs-control-bar .vjs-button {
  height: 100%;
}
.video-js .vjs-control {
  position: relative;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.video-js .vjs-control-bar .vjs-progress-control {
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  display: flex;
}
.video-js .vjs-progress-holder {
  -webkit-box-flex: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  height: 0.3em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}
.video-js .vjs-lcn {
  text-shadow: 1px 1px 1px #000;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.video-js .vjs-lcn-poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  margin: 0;
  padding: 0;
}
.video-js .vjs-big-play-tiny {
  background-position: 20px center !important;
  height: 66px !important;
  width: 66px !important;
  margin: -33px 0 0 -33px !important;
}
.vjs-sharing-container .vjs-share-block {
  display: block;
  margin: 0;
}
.video-js .vjs-control-bar {
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.1s, opacity 0.1s;
  transition: visibility 0.1s, opacity 0.1s;
}
.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-touch-active):not(.vjs-ad-playing)
  .vjs-control-bar {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.3s, opacity 0.3s;
  transition: visibility 0.3s, opacity 0.3s;
}
.vjs-touch-active .vjs-forward-control,
.vjs-touch-active .vjs-rewind-control {
  display: none;
}
.vjs-overflow .vjs-control-bar {
  visibility: visible !important;
  opacity: 1 !important;
}
.video-js .vjs-big-play-button {
  background-color: rgba(255, 255, 255, 0.3);
  border: 3px solid #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 86px;
  color: #fff;
  height: 86px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 3;
  text-align: center;
}
.video-js .vjs-big-play-button:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent #fff;
  margin-left: 7pt;
}
.video-js .vjs-big-button,
.video-js .vjs-replay-button {
  color: #fff;
  position: absolute;
  background: 0 0;
  width: auto;
  cursor: pointer;
  top: 50%;
}
.video-js .vjs-big-button {
  border: 0;
  z-index: 3;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
}
.video-js .vjs-b-r-b {
  left: 30%;
}
.vjs-480 .vjs-b-r-b {
  left: 20%;
}
.video-js .vjs-b-r-b:after {
  content: "\e901";
  font-size: 60px;
  font-family: nuevo;
}
.vjs-480 .vjs-b-r-b:after {
  font-size: 45px;
}
.video-js .vjs-b-f-b span,
.video-js .vjs-b-r-b span {
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  bottom: 6px;
}
.video-js .vjs-b-r-b span {
  right: 30px;
}
.video-js .vjs-b-f-b span {
  left: 26px;
}
.vjs-480 .vjs-b-r-b span {
  font-size: 16px;
  bottom: 4px;
  right: 22px;
}
.vjs-480 .vjs-b-f-b span {
  font-size: 16px;
  bottom: 4px;
  left: 20px;
}
.video-js .vjs-b-f-b {
  left: 70%;
}
.vjs-480 .vjs-b-f-b {
  left: 80%;
}
.video-js .vjs-b-f-b:after {
  content: "\e902";
  font-size: 60px;
  font-family: nuevo;
}
.vjs-480 .vjs-b-f-b:after {
  font-size: 45px;
}
.video-js .vjs-b-p-b {
  left: 50%;
  font-family: nuevo;
}
.video-js.vjs-playing .vjs-b-p-b:before {
  content: "\f103";
  font-size: 80px;
}
.video-js.vjs-paused .vjs-b-p-b:before {
  content: "\f101";
  font-size: 80px;
}
.vjs-480 .vjs-b-p-b:before {
  font-size: 60px !important;
}
.vjs-has-started.vjs-touch-active:not(.vjs-seeking):not(.vjs-ended) .vjs-b-p-b {
  opacity: 1;
  pointer-events: auto;
}
.vjs-has-started .vjs-big-play-button {
  pointer-events: none;
  opacity: 0;
}
.vjs-touch-active:not(.vjs-seeking):not(.vjs-live):not(.vjs-ended) .vjs-b-f-b,
.vjs-touch-active:not(.vjs-seeking):not(.vjs-live):not(.vjs-ended) .vjs-b-r-b {
  pointer-events: auto !important;
  opacity: 1 !important;
}
.vjs-touch-active .vjs-background-bar,
.vjs-touch-active .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}
.vjs-touch-inactive .vjs-background-bar,
.vjs-touch-inactive .vjs-control-bar {
  opacity: 0;
  visibility: hidden;
}
.video-js .vjs-visible,
.vjs-lock-menu {
  visibility: visible !important;
  opacity: 1 !important;
}
.video-js .vjs-replay-button {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 0;
  display: none;
}
.video-js .vjs-replay-button:before {
  content: "\ea47";
  padding-bottom: 5px;
  font-family: nuevo;
  font-size: 90px;
  display: block;
}
.vjs-ended .vjs-replay-button {
  display: block;
}
.video-js .vjs-time-control {
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  color: #444;
  font-weight: 700;
  -webkit-text-size-adjust: none;
}
.video-js .vjs-time-divider:before {
  content: "/";
}
.video-js .vjs-time-divider {
  line-height: 40px;
  padding: 0 3px;
  position: relative;
}
.video-js .vjs-current-time {
  margin-left: 5px;
}
.video-js .vjs-duration-display {
  margin-right: 5px;
}
.video-js .vjs-menu {
  background-color: #eee;
  background-color: rgba(238, 238, 238, 1);
  font-family: Arial, Helvetica, sans-serif !important;
  min-width: 34px;
}
.video-js .vjs-progress-control {
  margin: 0 20px;
}
.video-js .vjs-progress-control .vjs-slider {
  cursor: pointer;
  height: 100%;
}
.video-js .vjs-progress-control .vjs-slider:before {
  background: #000;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  border: 0;
}
.video-js .vjs-progress-control .vjs-load-progress,
.vjs-progress-control .vjs-play-progress {
  height: 2px;
  left: 0;
  position: absolute;
  top: 20px;
}
.video-js .vjs-progress-control .vjs-load-progress {
  background-color: #999;
}
.video-js .vjs-progress-control .vjs-play-progress {
  background-color: #04aed6;
  width: 0;
}
.video-js .vjs-menu-button-inline .vjs-slider .vjs-volume-level:before,
.video-js .vjs-progress-control .vjs-play-progress:before {
  background: #04aed6;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  content: "";
  height: 12px;
  position: absolute;
  right: -6px;
  top: -5px;
  width: 12px;
}
.video-js .vjs-progress-control .vjs-play-progress:after {
  display: none !important;
}
.video-js .vjs-progress-control .vjs-mouse-display {
  display: none;
  position: absolute;
  width: 1px;
  height: 20px;
  z-index: 1;
  top: 0;
}
.video-js
  .vjs-progress-control
  .vjs-mouse-display
  .vjs-no-flex
  .vjs-progress-control
  .vjs-mouse-display {
  z-index: 0;
}
.video-js .vjs-progress-control:hover .vjs-mouse-display {
  display: block;
}
.video-js .vjs-progress-control .vjs-mouse-display .vjs-time-tooltip {
  top: -18px;
}
.video-js.vjs-user-inactive.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
  display: none;
}
.video-js .vjs-time-tooltip {
  white-space: nowrap;
  position: relative;
  float: right;
  top: -43px;
  background-color: #eee;
  color: #000;
  padding: 5px;
  -webkit-border-radius: 0.3em;
  border-radius: 0.3em;
  font-size: 12px;
  pointer-events: none;
  visibility: hidden;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
}
.video-js .vjs-abs-hidden {
  display: none !important;
}
.video-js .vjs-progress-holder:focus .vjs-time-tooltip {
  display: none;
}
.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-time-tooltip {
  display: block;
  font-size: 0.8em;
  visibility: visible;
}
.vjs-menu .vjs-selected {
  background-color: #ccc !important;
  color: #000 !important;
}
.video-js .vjs-menu-settings {
  bottom: 40px;
  background-color: #eee;
  cursor: default;
  right: -15px;
  position: absolute;
  padding: 2px 0;
  width: auto;
  color: #000;
  font-family: Arial, sans-serif !important;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.video-js .vjs-menu-settings .vjs-min-max {
  max-height: 0 !important;
  max-width: 0 !important;
}
.video-js .vjs-menu-settings .vjs-menu-div {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
  -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.vjs-menu-div::-webkit-scrollbar {
  width: 4px;
  background: #e0e0e0;
}
.vjs-menu-div::-webkit-scrollbar-thumb {
  background: #fff;
}
.vjs-menu-div::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
.video-js .vjs-invisible {
  visibility: hidden;
}
.video-js .vjs-menu-settings .vjs-menu-content {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 13px;
  background-color: #eee;
}
.video-js .vjs-menu-settings .vjs-menu-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.video-js .vjs-menu-settings .vjs-menu-content li {
  height: 26px;
  line-height: 26px;
  padding: 0 15px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}
.video-js .vjs-menu .vjs-qlist li i,
.video-js .vjs-qlist {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.video-js .vjs-menu-settings .vjs-menu-content li span {
  align-items: center;
  margin-left: auto;
  pointer-events: none;
  padding-left: 40px;
  padding-right: 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
}
.video-js .vjs-menu-settings .vjs-menu-content .vjs-speed {
  padding-left: 30px;
}
.video-js .vjs-menu-settings .vjs-menu-content .vjs-first {
  margin-top: 5px;
}
.video-js .vjs-menu-settings .vjs-related-icon,
.video-js .vjs-menu-settings .vjs-share-icon {
  font-family: nuevo;
  font-size: 17px;
  pointer-events: none;
}
.video-js .vjs-menu-settings .vjs-share-icon:before {
  content: "\f10f";
}
.video-js .vjs-menu-settings .vjs-related-icon:before {
  content: "\f00a";
}
.video-js .vjs-menu-settings .vjs-menu-content li:after {
  border: 4px solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video-js .vjs-menu-settings .vjs-menu-content .vjs-menu-forward:after {
  right: 5px;
  border-left-color: #000;
}
.video-js .vjs-menu-settings .vjs-settings-back {
  border-bottom: solid 1px #fff;
  padding-left: 20px !important;
  position: relative;
}
.video-js .vjs-menu-settings .vjs-settings-back:before {
  border: 4px solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
  border-right-color: #000;
}
.vjs-has-mouse .vjs-menu-settings .vjs-menu-content li:hover {
  background-color: #fff;
}
.video-js .vjs-menu-settings .vjs-settings-back:hover {
  background-color: transparent !important;
}
.vjs-extend-quality .quality-label .vjs-hd-home {
  margin-top: -8px;
}
.vjs-submenu .item-quality .vjs-hd-icon {
  height: 11px;
  font-size: 8px !important;
  padding: 0 1px !important;
}
.video-js .vjs-menu .vjs-qlist li i {
  font-style: normal;
  font-size: 10px;
  pointer-events: none;
}
.video-js .vjs-zoom-slide {
  position: absolute;
  top: 15px;
  right: 0;
  width: 40px;
  background: 0 0;
  display: none;
}
.vjs-zoom-slide .zoom-bg,
.vjs-zoom-slide .zoom-thumb {
  position: absolute;
  width: 4px;
  left: 50%;
  margin-left: -2px;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.vjs-zoom-slide .zoom-thumb {
  background: #fff;
  top: auto;
  bottom: 0;
  height: 0%;
}
.vjs-has-started.vjs-paused .vjs-zoom-slide,
.vjs-has-started.vjs-user-active .vjs-zoom-slide {
  display: block;
}
.vjs-slide-block {
  display: block !important;
}
.vjs-background-bar {
  pointer-events: none;
}
.video-js .vjs-zoom-menu {
  background-color: #eee;
}
.video-js .vjs-zoom-menu .vjs-zoom-return {
  line-height: 30px;
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
}
.video-js .vjs-zoom-menu .vjs-zoom-slider {
  height: 70px;
  position: relative;
  display: table;
  margin: 0 auto 8px;
  width: 100%;
}
.video-js .vjs-show {
  display: block !important;
}
.video-js .vjs-zoom-menu .vjs-zoom-back {
  background: #ccc;
  width: 6px;
  margin-left: -3px;
  left: 50%;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.video-js .vjs-zoom-menu .vjs-zoom-level {
  background: #04aed6;
  bottom: 1px;
  position: absolute;
  width: 6px;
  margin-left: -3px;
  left: 50%;
  border: 1px solid transparent;
  border-bottom: 0;
  border-top: 0;
  cursor: pointer;
}
.video-js .vjs-zoom-menu .vjs-zoom-level:before {
  background: #04aed6;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 12px;
  position: absolute;
  right: -4px;
  top: -6px;
  width: 12px;
}
.video-js .vjs-zoom-menu .vjs-zoom-reset {
  font-size: 11px;
  text-align: center;
  width: 100%;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  padding: 0 4px;
  border-top: solid 1px #fff;
}
.video-js .vjs-zoom-menu .vjs-zoom-reset:hover {
  background-color: #ccc;
}
.video-js .item-quality {
  padding: 2px 20px 2px 26px !important;
  position: relative !important;
}
.auto-res i {
  padding: 0 0 0 8px !important;
  font-weight: 400;
}
.video-js .item-quality .vjs-hd-icon {
  vertical-align: top;
  display: table;
  margin: 1px 0 0 1px;
  font-size: 9px;
  padding: 0 0 0 1px;
}
.video-js .autores {
  padding: 0 0 0 8px !important;
}
.video-js .vjs-hd,
.video-js .vjs-hd-btn,
.video-js .vjs-hd-icon {
  color: #fff;
  font-size: 8px;
  padding: 0 1px;
  font-style: normal;
}
.video-js .vjs-hd-btn {
  font-family: sans-serif, Arial;
  position: absolute;
  display: block;
  background: #c00;
  top: 10px;
  right: 5px;
  font-weight: 400;
  line-height: normal;
}
.video-js .vjs-hd-btn:before {
  content: "HD";
}
.video-js .vjs-hd-icon {
  font-family: sans-serif;
  display: inline-block;
  font-weight: 700;
  border: 1px solid #c00;
  background-color: #c00;
  line-height: normal;
  width: auto;
  margin-left: 4px;
  vertical-align: super;
}
.video-js .vjs-hd {
  font-weight: 700;
  background: #c00;
  position: absolute;
  top: 25%;
  right: 7%;
  pointer-events: none;
}
.video-js .vjs-hd-menu {
  position: absolute;
  top: 2px;
  right: 8px;
}
.player-container.fallback video,
.player-cover,
.vjs-error-display,
.vjs-poster,
.vjs-thumb-poster {
  top: 0;
  position: absolute;
}
.video-js .vjs-hd-home {
  right: 11px;
}
.is-touch .vjs-has-started .vjs-poster,
.is-touch .vjs-has-started .vjs-tech {
  pointer-events: none;
}
.vjs-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.vjs-has-started .vjs-poster {
  opacity: 0;
}
.video-js audio,
.vjs-audio .vjs-poster,
.vjs-poster-on {
  opacity: 1 !important;
}
.vjs-has-mouse.vjs-audio .vjs-poster {
  pointer-events: auto !important;
}
.video-js.vjs-fullscreen.vjs-user-inactive .vjs-poster {
  cursor: none;
}
.noTouch .video-js:hover .vjs-big-play-button {
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.86);
}
.player-container.fallback {
  background: #000 !important;
}
.player-container.fallback video {
  display: block;
  height: 100%;
  left: 0;
  width: 100%;
}
.player-container.stream-link {
  background-repeat: no-repeat;
  background-size: contain;
}
.player-cover {
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 5;
}
.vjs-error-display {
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.75);
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 3;
}
.vjs-error-display .vjs-close {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #ccc;
  border-bottom: solid 1px #ccc;
  cursor: pointer;
  z-index: 3;
}
.vjs-error-display .vjs-close:hover {
  color: #fff;
  border-bottom: solid 1px #fff;
}
.vjs-error-display .vjs-modal-dialog-content {
  margin: 22px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
}
.vjs-error-display .vjs-modal-dialog-description {
  display: none;
  text-align: center;
}
.vjs-error .vjs-error-display {
  display: block;
  text-align: center;
}
.ios-lt-8 .vjs-control-bar {
  display: none !important;
}
.preview-preload {
  display: none;
  position: absolute;
}
.vjs-thumb-poster {
  left: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  display: inline-block;
  background: #000;
}
.vjs-thumb-poster canvas {
  opacity: 0.5;
  pointer-events: none;
}
.vjs-thumbnail-holder {
  position: absolute;
  overflow: hidden;
  left: -1000px;
  bottom: 44px;
  border: 2px solid #eee;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transfrom-origin: 50% 100%;
  transfrom-origin: 50% 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  -webkit-transition: transform 0.3s ease, opacity 0.3s;
  pointer-events: none;
  opacity: 0;
}
.vjs-thumb-image {
  position: absolute;
  left: 0;
}
.vjs-sld {
  bottom: 24px !important;
}
.vjs-vtt {
  bottom: 68px !important;
}
.vjs-thumb-tooltip {
  text-align: center;
  bottom: 4px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  padding: 2px 3px;
  position: absolute;
  display: table;
  font-size: 14px;
  width: 100%;
}
.vjs-thumb-hidden {
  opacity: 0 !important;
}
.vjs-progress-control:active .vjs-thumb-image:active {
  opacity: 0;
}
.video-js .vjs-live-control {
  line-height: 40px;
  padding: 0 10px;
  color: #444;
}
.video-js .vjs-live-display:before {
  content: "";
  height: 8px;
  width: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #999;
  display: inline-block;
  margin-right: 6px;
}
.vjs-playing .vjs-live-display:before {
  background-color: red;
}
.vjs-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.vjs-tech-chromecast {
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.vjs-tech-chromecast .vjs-tech-chromecast-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.5;
}
.vjs-tech-chromecast .vjs-tech-chromecast-subtitle.vjs-tech-chromecast-subtitle-empty,
.vjs-tech-chromecast .vjs-tech-chromecast-title.vjs-tech-chromecast-title-empty {
  display: none;
}
.vjs-tech-chromecast .vjs-tech-chromecast-poster-img {
  max-height: 180px;
  width: auto;
  border: 2px solid #ccc;
}
.vjs-tech-chromecast .vjs-tech-chromecast-poster-img.vjs-tech-chromecast-poster-img-empty {
  width: 160px;
  height: 90px;
}
.vjs-tech-chromecast .vjs-tech-chromecast-title-container {
  position: absolute;
  top: 50px;
  color: #ccc;
  left: 20px;
}
.vjs-tech-chromecast .vjs-tech-chromecast-title {
  font-size: 22px;
}
.vjs-tech-chromecast .vjs-tech-chromecast-subtitle {
  font-size: 18px;
  padding-top: 0.5em;
}
.vjs-tech-chromecast .vjs-tech-chromecast-device {
  font-size: 22px;
  margin-bottom: 15px;
  border: 0;
  border-bottom: 2px solid;
  display: table;
  border-image: linear-gradient(to right, #00f 25%, red 25%, red 50%, #ff0 50%, #ff0 75%, green 75%)
    5;
}
.vjs-tech-chromecast .vjs-tech-chromecast-device-empty {
  display: none;
}
.video-js .vjs-mirror-button,
.video-js .vjs-mirrored {
  color: #383838;
  font-family: nuevo;
  font-size: 18px;
  text-align: center;
  width: 34px;
  padding: 0;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 18px;
  line-height: 40px;
  font-family: nuevo;
  color: #383838;
}
.video-js .vjs-chromecast-button {
  background: transparent;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  text-shadow: 1px 1px 1px #000;
}
.vjs-chromecast-button .vjs-icon-placeholder:before {
  content: "\e905";
  color: #fff;
  font-size: 28px;
}
.vjs-chromecast-button.vjs-chromecast-casting-state .vjs-icon-placeholder:before {
  content: "\e906";
  color: #fff;
  font-size: 28px;
}
.video-js .vjs-control-bar .vjs-chromecast-button {
  position: relative;
  top: 0;
  left: 0;
  text-shadow: none;
}
.vjs-control-bar .vjs-chromecast-button .vjs-icon-placeholder:before,
.vjs-control-bar .vjs-chromecast-button.vjs-chromecast-casting-state .vjs-icon-placeholder:before {
  font-size: 18px;
  color: #383838;
  line-height: 38px;
}
.video-js .vjs-subs-caps-button .vjs-icon-placeholder:before {
  content: "\f10d";
  font-size: 20px;
}
.video-js .vjs-menu .vjs-icon-placeholder:before {
  content: "";
}
.video-js .vjs-play-control {
  transform: none;
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  content: "\f103";
  font-size: 20px;
}
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder:before {
  content: "\f101";
  font-size: 20px;
}
.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
  content: "\f116";
  font-size: 20px;
}
.video-js .vjs-mode-control {
  font-family: nuevo;
  text-align: center;
  color: #222;
  font-size: 20px;
  line-height: 42px;
}
.video-js .vjs-mode-control:before {
  content: "\e907";
}
.video-js .vjs-mode:before {
  content: "\e908";
}
.vjs-download-control .vjs-icon-placeholder:before {
  content: "\e909";
  font-size: 18px;
}
.vjs-rewind-control .vjs-icon-placeholder:before {
  content: "\e901";
  font-size: 20px;
}
.vjs-forward-control .vjs-icon-placeholder:before {
  content: "\e902";
  font-size: 20px;
}
.vjs-forward-control .num,
.vjs-rewind-control .num {
  position: absolute;
  font-size: 8px;
  font-weight: 700;
  top: 54%;
  font-family: Arial, sans-serif;
}
.vjs-rewind-control .num {
  left: 8px;
}
.vjs-forward-control .num {
  left: 16px;
}
.vjs-live:not(.vjs-liveui) .vjs-forward-control,
.vjs-live:not(.vjs-liveui) .vjs-rewind-control {
  display: none !important;
}
.video-js .vjs-snap-control .vjs-icon-placeholder:before {
  content: "\e90c";
}
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\f065";
}
.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "\f066";
}
.video-js .vjs-audio-button .vjs-icon-placeholder:before {
  content: "\f11e";
}
.video-js .vjs-chapters-button .vjs-icon-placeholder:before {
  content: "\f10e";
  font-size: 20px;
}
.video-js .vjs-descriptions-button .vjs-icon-placeholder:before {
  content: "\f11d";
  font-size: 20px;
}
.video-js .vjs-mirror-button,
.video-js .vjs-mirrored {
  line-height: 42px;
}
.video-js .vjs-mirror-button:before {
  content: "\ea45";
}
.video-js .vjs-mirrored:before {
  content: "\ea46";
}
.vjs-cog-button .vjs-icon-placeholder:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
  display: block;
}
.vjs-has-mouse .vjs-cog-active .vjs-icon-placeholder:before {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.video-js .vjs-cog-menu-button .vjs-icon-placeholder:before {
  content: "\f110";
}
.video-js .vjs-subtitles-button .vjs-menu,
.vjs-audio-button .vjs-menu,
.vjs-chapters-button .vjs-menu,
.vjs-descriptions-button .vjs-menu,
.vjs-quality-button .vjs-menu,
.vjs-subs-caps-button .vjs-menu {
  background-color: #eee;
  background-color: rgba(238, 238, 238, 1);
  bottom: 40px;
  color: #000;
  border-bottom: 0;
  right: -50%;
  margin: 0;
  padding: 0;
  position: absolute;
  width: auto;
  z-index: 1;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.vjs-bottom {
  bottom: 0 !important;
}
.vjs-menu-bottom {
  bottom: 10px !important;
}
.video-js .vjs-quality-button {
  line-height: 40px;
  min-width: 40px;
  text-align: center;
}
.video-js .vjs-quality-button span {
  font-size: 12px;
  color: #222;
  font-weight: 700;
  padding: 0 4px;
  white-space: nowrap;
}
.video-js .vjs-menu-button .vjs-hidden {
  display: inline-none !important;
  visibility: hidden;
  opacity: 0 !important;
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}
.video-js:not(.vjs-480) .vjs-audio-button:hover .vjs-menu,
.video-js:not(.vjs-480) .vjs-chapters-button:hover .vjs-menu,
.video-js:not(.vjs-480) .vjs-descriptions-button:hover .vjs-menu,
.video-js:not(.vjs-480) .vjs-subs-caps-button:hover .vjs-menu {
  display: block;
}
.video-js .vjs-menu .vjs-menu-content {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 12px;
  font-family: Arial, sans-serif;
  text-align: left;
  line-height: normal;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}
.vjs-menu .vjs-menu-content::-webkit-scrollbar {
  width: 6px;
  background: #e0e0e0;
}
.vjs-menu .vjs-menu-content::-webkit-scrollbar-thumb {
  background: #fff;
}
.vjs-menu .vjs-menu-content::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
.video-js .vjs-menu .vjs-menu-content li {
  line-height: 26px;
  padding: 0 15px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
}
.vjs-has-mouse .vjs-menu .vjs-menu-content li:hover {
  background-color: #fff;
  color: #000;
}
.vjs-quality-button .vjs-menu .vjs-menu-content li {
  padding: 2px 25 0 30px;
  position: relative;
}
.vjs-menu-content .vjs-checked:before {
  font-family: nuevo;
  content: "\f00c";
  font-size: 0.8em;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vjs-quality-button .vjs-menu .vjs-hd-icon {
  border: 0;
  margin-left: -1px;
  display: inline-block;
  margin-top: 0;
  color: #fff;
  font-weight: 700;
  font-size: 8px !important;
  font-family: sans-serif;
  background: #c00;
  padding: 0 1px;
}
.vjs-quality-button span .vjs-hd-icon {
  vertical-align: top;
  margin-left: -5px;
  margin-top: 8px;
  border: 0;
  color: #fff;
  font-weight: 700;
  font-size: 8px;
  font-family: sans-serif;
  background: #c00;
}
.video-js .vjs-menu-button-inline {
  width: 120px;
}
.vjs-menu-button-inline .vjs-menu {
  height: 100%;
  width: auto;
  position: absolute;
  left: 40px;
  top: 0;
  padding: 0;
  margin: 0;
  display: block;
  background: 0 0;
}
.video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 20px !important;
  line-height: 41px;
}
.video-js .vjs-mute-control:not(:hover) .vjs-control-text {
  opacity: 0;
}
.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  content: "\f104";
}
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
  content: "\f105";
}
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
  content: "\f106";
}
.video-js .vjs-mute-control.vjs-vol-3 .vjs-icon-placeholder:before {
  content: "\f107";
}
.video-js .vjs-volume-control {
  cursor: pointer;
  width: 40px;
  font-family: nuevo;
}
.video-js .vjs-volume-panel {
  position: relative;
  margin-right: 5px;
}
.video-js .vjs-volume-panel .vjs-volume-horizontal {
  display: none;
  background-color: transparent !important;
  width: 70px;
  height: 2px;
  float: right;
  margin: 19px 5px 0;
}
.vjs-has-mouse .vjs-volume-panel .vjs-volume-horizontal {
  display: inline-block;
}
.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider {
  outline: 0;
  position: relative;
  cursor: pointer;
  padding: 0;
  background-color: #222;
  width: 65px;
  height: 2px;
}
.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider .vjs-volume-level {
  height: 100%;
  position: absolute;
  background-color: #04aed6;
  width: 100%;
}
.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider .vjs-volume-level:before {
  background: #04aed6;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  content: "";
  height: 12px;
  position: absolute;
  right: -6px;
  top: -5px;
  width: 12px;
}
.video-js .vjs-error-display {
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.75);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.video-js .vjs-error-display .vjs-modal-dialog-content {
  margin: 22px;
  font-size: 14px;
  font-size: 1.4rem;
}
.video-js .vjs-error-display .vjs-modal-dialog-description {
  display: none;
}
.video-js .vjs-error .vjs-error-display {
  display: block;
}
.video-js .ios-lt-8 .vjs-control-bar {
  display: none !important;
}
.vjs-resolution-button vjs-selected {
  background-color: #ccc;
  color: #222;
}
.video-js .vjs-sharing-overlay {
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
  width: 100%;
  z-index: 98;
}
.vjs-sharing-container {
  width: 100%;
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
.vjs-sharing-container .vjs-sharing-body {
  display: table;
  margin: 0 auto;
  position: relative;
}
.video-js .vjs-share-close {
  top: 5px;
  right: 10px;
}
.vjs-sharing-container .vjs-inputs-body {
  text-align: left;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
.vjs-sharing-container .vjs-inputs-body h2 {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 32px;
  color: #fff !important;
  text-shadow: 1px 1px 1px #000;
  margin: 0 !important;
  text-align: left !important;
  padding: 0 !important;
  font-family: Tahoma, Arial, sans-serif !important;
}
.vjs-sharing-container .vjs-inputs-body input[type="text"] {
  padding: 9px;
  background: #fff;
  width: 100%;
  border: 1px solid #d5d5d5;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em;
  color: #121212;
  box-sizing: border-box;
}
.vjs-sharing-container input[type="text"]:focus {
  background: #fff;
}
.video-js .vjs-sharing-overlay .vjs-sharing-close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 2em;
  outline: 0;
}
.vjs-sharing-container .vjs-share-icon {
  font-size: 5em;
  margin: 0 0.2em 0.2em;
  cursor: pointer;
  position: relative;
  outline: 0;
  font-family: nuevo;
  font-style: normal;
  display: inline-block;
}
.vjs-audio-info,
.vjs-info {
  visibility: visible;
  opacity: 1;
  font-family: sans-serif;
  text-align: left;
  text-shadow: 1px 1px 1px #000;
  font-weight: 400;
  left: 0;
  position: absolute;
  cursor: pointer;
}
.vjs-sharing-container .vjs-share-icon .vjs-control-content {
  margin-top: 5px;
}
.vjs-google-plus-square:before {
  content: "\f0d4";
}
.vjs-facebook-square:before {
  content: "\f082";
}
.vjs-twitter-square:before {
  content: "\f081";
}
.vjs-pinterest-square:before {
  content: "\f0d3";
}
.vjs-linkedin-square:before {
  content: "\f08c";
}
.vjs-sharing-container .vjs-share-icon:hover:before {
  color: #e5e5e5;
}
.vjs-audio .vjs-extend-zoom,
.vjs-audio .vjs-picture-in-picture-control,
.vjs-audio .vjs-progress-slide {
  display: none !important;
}
.vjs-audio-info,
.vjs-audio-info a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  max-width: 100%;
}
.vjs-audio-info {
  display: -moz-box;
  display: flex;
  top: 0;
  color: #fff;
  padding: 20px;
  font-size: 18px;
}
.vjs-480 .vjs-audio-info {
  padding: 10px;
}
.vjs-audio-info a {
  color: #fff !important;
  display: flex;
}
.vjs-audio-info a .vjs-audio-info .vjs-cover img,
.vjs-info {
  color: #fff;
}
.vjs-audio-info .vjs-cover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-right: 15px;
}
.vjs-audio-info .vjs-cover img {
  padding: 2px;
  border: 1px solid #999;
  height: 100%;
  width: 100%;
}
.vjs-audio-info .vjs-text {
  flex: auto;
  overflow: hidden;
}
.vjs-audio-info .vjs-audio-item {
  overflow: hidden;
  text-shadow: 1px 1px 1px #000;
}
.vjs-audio-item .audio-artist,
vjs-song .vjs-audio-item .vjs-song {
  font-size: 14px;
  line-height: 20px;
  display: block;
}
.vjs-audio-item .audio-id {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  line-height: 16px;
}
.vjs-audio-item .audio-id span {
  display: block;
}
.vjs-360 .vjs-audio-info .vjs-cover,
.vjs-480 .vjs-audio-info .vjs-cover {
  height: 100px;
  width: 100px;
}
.vjs-640 .vjs-audio-info .vjs-cover {
  height: 124px;
  width: 124px;
}
.vjs-920 .vjs-audio-info .vjs-cover {
  height: 164px;
  width: 164px;
}
.vjs-920 .vjs-audio-item .audio-artist {
  font-size: 20px;
  line-height: 30px;
}
.vjs-920 .vjs-audio-item .vjs-song {
  font-size: 26px;
}
.vjs-920 .vjs-audio-item .audio-id {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
}
.vjs-1280 .vjs-audio-info .vjs-cover {
  height: 194px;
  width: 194px;
  margin-right: 20px;
}
.vjs-1280 .vjs-audio-item .audio-artist {
  font-size: 24px;
  line-height: 34px;
}
.vjs-1280 .vjs-audio-item .vjs-song {
  font-size: 32px;
}
.vjs-1280 .vjs-audio-item .audio-id {
  margin-top: 25px;
  font-size: 16px;
  line-height: 22px;
}
.vjs-1600 .vjs-audio-info {
  padding: 40px;
}
.vjs-1600 .vjs-audio-info .vjs-cover {
  height: 234px;
  width: 234px;
  margin-right: 25px;
}
.vjs-1600 .vjs-audio-item .audio-artist {
  font-size: 28px;
  line-height: 38px;
}
.vjs-1600 .vjs-audio-item .vjs-song {
  font-size: 38px;
}
.vjs-1600 .vjs-audio-item .audio-id {
  margin-top: 35px;
  font-size: 20px;
  line-height: 26px;
}
.vjs-info {
  display: flex;
  padding: 10px;
  font-size: 18px;
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.vjs-info-top {
  top: 0;
  background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.6));
}
.vjs-info-bottom {
  bottom: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  padding: 20px 10px 15px;
}
.vjs-info .vjs-icon {
  display: flex;
  align-items: center;
}
.vjs-info img {
  padding: 0 10px;
  vertical-align: middle;
}
.vjs-info a {
  color: #fff !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
  display: flex;
}
.vjs-info .vjs-dsc,
.vjs-info .vjs-ttl {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vjs-info .vjs-text {
  flex: auto;
  overflow: hidden;
}
.vjs-info .vjs-dsc {
  margin-top: 4px;
  opacity: 0.85;
  font-size: 80%;
}
.vjs-cast-button .vjs-info {
  padding: 10px 60px;
}
.vjs-casting .vjs-info {
  top: -40px;
}
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-info {
  opacity: 0;
  visibility: hidden;
  top: -40px;
}
.video-js .vjs-over {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
  z-index: 99;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}
.video-js .vjs-fade {
  pointer-events: auto;
  opacity: 1;
}
.vjs-lock-showing,
.vjs-lock-showing .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}
.video-js .vjs-over .over-inn {
  display: table;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  margin: 0;
  text-align: center;
}
.video-js .vjs-overlay {
  position: absolute;
  z-index: 98;
  max-width: 88%;
  max-height: 80%;
  display: none;
  background: #f5f5f5;
  color: #121212;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
}
.video-js .vjs-overlay-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-js .vjs-overlay-bottom {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 60px;
}
.video-js .vjs-overlay .pre-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-js .vjs-overlay .content {
  text-align: center;
}
.video-js .vjs-overlay .vjs-overlay-close {
  top: -18px;
  right: -18px;
}
.vjs-sharing-container .vjs-share-icon .vjs-control-text {
  position: absolute;
  width: 100%;
  font-size: 0.15em;
  font-weight: 700;
  text-align: center;
  left: 0;
  bottom: -1em;
  clip: initial;
  height: initial;
  margin: 0;
}
.vjs-sharing-overlay .share-icon-remove {
  font-family: nuevo;
  color: #fff;
}
.vjs-sharing-overlay .share-icon-remove:before {
  content: "\f00d";
}
.vjs-text-track-settings {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  outline: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #111;
  margin: 0 auto;
  height: 18em !important;
  max-height: 90%;
  font-size: 12px !important;
  max-width: 350px;
  width: 90%;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  z-index: 999;
  background-color: #fff !important;
}
.vjs-text-track-settings .vjs-modal-dialog-content {
  top: 0;
  bottom: 4em;
  left: 0;
  right: 0;
  position: absolute;
  overflow: auto;
  padding: 6px !important;
  height: auto !important;
}
.vjs-text-track-settings fieldset {
  padding: 8px !important;
  width: auto !important;
  border: 0 !important;
  margin: 5px;
}
.vjs-text-track-settings legend {
  font-size: 13px;
  color: #fff;
  margin: 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  background-color: #222;
}
.vjs-text-track-settings select {
  padding: 6px !important;
  border: 1px solid #777;
  background: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
}
.vjs-text-track-settings .vjs-track-settings-controls {
  position: fixed;
  bottom: 10px;
  right: 1em;
}
.vjs-text-track-settings .vjs-close-button {
  display: none !important;
}
.vjs-text-track-settings .vjs-track-settings-controls button {
  cursor: pointer;
  width: auto !important;
  background: #e5e5e5 !important;
  color: #121212 !important;
  -webkit-border-radius: 5px !important;
  border-radius: 3px !important;
  margin: 0 3px;
  font-size: 12px;
  text-shadow: none;
  border: 0;
  padding: 3px 6px !important;
}
.vjs-text-track-settings .vjs-track-settings-controls button:hover {
  background: #b5b5b5 !important;
}
.vjs-text-track-settings .vjs-tracksetting {
  margin: 5px;
  padding: 3px;
  min-height: 40px;
}
.vjs-text-track-settings label {
  display: inline-block;
  width: 100px;
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 12px;
}
.vjs-text-track-settings span {
  display: inline;
  margin-left: 5px;
}
.vjs-text-track-settings > div {
  margin-bottom: 5px;
  min-height: 20px;
}
.vjs-text-track-settings > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  min-height: 0;
}
.vjs-caption-settings label > input {
  margin-right: 10px;
}
.vjs-text-track-settings input[type="button"] {
  width: 40px;
  height: 40px;
}
.video-js .vjs-modal-dialog {
  background-color: rgba(0, 0, 0, 0.66);
  overflow: hidden;
  z-index: 8;
}
.video-js .vjs-modal-dialog,
.vjs-modal-dialog .vjs-modal-dialog-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vjs-modal-dialog .vjs-modal-dialog-content {
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 20px 24px;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 #fff;
}
.vjs-modal-dialog .vjs-modal-dialog-content::-webkit-scrollbar {
  width: 6px;
  background: 0 0;
}
.vjs-modal-dialog .vjs-modal-dialog-content::-webkit-scrollbar-thumb {
  background: #ccc;
}
.vjs-modal-dialog .vjs-modal-dialog-content::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.vjs-text-track-display {
  position: absolute;
  bottom: 4em;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 2em;
}
.video-js .vjs-text-track {
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}
.vjs-subtitles {
  color: #fff;
}
.vjs-captions {
  color: #fc6;
}
.vjs-tt-cue {
  display: block;
}
video::-webkit-media-text-track-display {
  -webkit-transform: translateY(-3em);
  transform: translateY(-3em);
}
.video-js.vjs-user-inactive.vjs-playing video::-webkit-media-text-track-display {
  -webkit-transform: translateY(-1.5em);
  transform: translateY(-1.5em);
}
.video-js .vjs-offscreen {
  height: 1px;
  left: -9999px;
  position: absolute;
  top: 0;
  width: 1px;
}
.vjs-lock-showing {
  display: block !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}
.vjs-no-js {
  padding: 20px;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 300px;
  height: 150px;
  margin: 0 auto;
}
.vjs-no-js a,
.vjs-no-js a:visited {
  color: #66a8cc;
}
.video-js .vjs-control.vjs-close-button {
  cursor: pointer;
  height: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
  z-index: 2;
}
.vjs-progress-slide {
  position: absolute;
  left: 0;
  z-index: 1;
  top: -5px;
}
.vjs-progress-slide .vjs-thumb {
  background-color: #000;
  overflow: hidden;
  text-align: left;
  width: 192px;
  height: 108px;
  border-color: rgba(255, 255, 255, 0.8);
  border-style: solid;
  border-width: 1px;
  bottom: 2px;
  display: block;
  left: -120px;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  position: absolute;
  transition: transform 0.3s ease, opacity 0.3s ease;
  -webkit-transition: transform 0.3s ease, opacity 0.3s;
  pointer-events: none;
}
.video-js .vjs-thumb-show {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.vjs-progress-slide .vjs-thumb .vjs-thumb-duration {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  width: 100%;
  font-size: 14px;
  line-height: 1.5em;
  text-shadow: 1px 1px 1px #000;
  text-align: center;
}
.vjs-progress-slide .vjs-thumb img {
  position: absolute;
  left: 0;
  top: 0;
  max-width: none;
  max-height: none;
}
.video-js .vjs-loading-spinner {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84px;
  height: 84px;
  margin-top: -42px;
  margin-left: -42px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.75);
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
  display: block;
}
.vjs-scrubbing .vjs-b-f-b,
.vjs-scrubbing .vjs-b-p-b,
.vjs-scrubbing .vjs-b-r-b,
.vjs-scrubbing .vjs-brightness,
.vjs-scrubbing .vjs-loading-spinner {
  display: none;
}
.vjs-scrubbing .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}
.video-js .vjs-control-bar {
  z-index: 5;
  opacity: 0.8;
}
.video-js .vjs-control-bar .vjs-VR-control {
  cursor: pointer !important;
  font-size: 1.3em !important;
  line-height: 38px;
  color: #444;
}
.videojs .vjs-out {
  left: -1000px !important;
}
.clearfix::after,
clearfix::before {
  content: " ";
  display: table;
}
.vjs-live .vjs-progress-control {
  visibility: hidden;
}
.vjs-live .vjs-resolution-button,
.vjs-live .vjs-time-control {
  display: none;
}
.video-js .vjs-limit-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.88);
  z-index: 1999;
}
.video-js .vjs-limit {
  display: table;
  margin: 0 auto;
  color: #ccc !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  width: 75%;
  text-align: center;
  font-size: 1.4em;
  padding: 0 20px;
  text-shadow: 1px 1px 1px #000;
}
.video-js .vjs-limit span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.video-js .vjs-limit a {
  color: #fff !important;
}
.video-js .vjs-limit a:hover {
  text-decoration: underline;
}
.video-js .vjs-limit img {
  max-width: 100%;
  height: auto;
}
.video-js.vjs-liveui .vjs-live-control,
.video-js:not(.vjs-live) .vjs-live-control {
  display: none;
}
.video-js .vjs-seek-to-live-control {
  cursor: pointer;
  padding: 0 10px;
  width: auto;
  background: 0 0;
  border: 0;
  outline: 0;
}
.video-js .vjs-seek-to-live-control .vjs-icon-placeholder:before {
  content: "";
  height: 8px;
  width: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #888;
  display: inline-block;
  margin-right: 6px;
}
.vjs-no-flex .vjs-seek-to-live-control {
  display: table-cell;
  width: auto;
  text-align: left;
}
.video-js.vjs-live:not(.vjs-liveui) .vjs-seek-to-live-control,
.video-js:not(.vjs-live) .vjs-seek-to-live-control {
  display: none;
}
.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge {
  cursor: auto;
}
.vjs-seek-to-live-control .vjs-icon-placeholder {
  margin-right: 3px;
  color: #888;
}
.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge .vjs-icon-placeholder:before {
  background-color: red;
}
.vjs-liveui .vjs-progress-control {
  display: flex;
  align-items: center;
  visibility: visible;
}
.vjs-live-reload .vjs-control-bar .vjs-live-control,
.vjs-liveui-reload .vjs-control-bar .vjs-seek-to-live-control {
  display: block !important;
}
.vjs-live-reload .vjs-control-bar .vjs-forward-control,
.vjs-live-reload .vjs-control-bar .vjs-rewind-control,
.vjs-live-reload .vjs-time-control,
.vjs-live-reload .vjs-time-divider,
.vjs-liveui-reload .vjs-control-bar .vjs-forward-control,
.vjs-liveui-reload .vjs-control-bar .vjs-rewind-control,
.vjs-liveui-reload .vjs-time-control,
.vjs-liveui-reload .vjs-time-divider {
  display: none;
}
.vjs-live-reload .vjs-progress-control {
  visibility: hidden;
}
.video-js .vjs-button-vr .vjs-icon-placeholder {
  height: 100%;
  width: 30px;
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzM4MzgzOCI+CiAgICA8cGF0aCBkPSJNMjAuNzQgNkgzLjIxQzIuNTUgNiAyIDYuNTcgMiA3LjI4djEwLjQ0YzAgLjcuNTUgMS4yOCAxLjIzIDEuMjhoNC43OWMuNTIgMCAuOTYtLjMzIDEuMTQtLjc5bDEuNC0zLjQ4Yy4yMy0uNTkuNzktMS4wMSAxLjQ0LTEuMDFzMS4yMS40MiAxLjQ1IDEuMDFsMS4zOSAzLjQ4Yy4xOS40Ni42My43OSAxLjExLjc5aDQuNzljLjcxIDAgMS4yNi0uNTcgMS4yNi0xLjI4VjcuMjhjMC0uNy0uNTUtMS4yOC0xLjI2LTEuMjh6TTcuNSAxNC42MmMtMS4xNyAwLTIuMTMtLjk1LTIuMTMtMi4xMiAwLTEuMTcuOTYtMi4xMyAyLjEzLTIuMTMgMS4xOCAwIDIuMTIuOTYgMi4xMiAyLjEzcy0uOTUgMi4xMi0yLjEyIDIuMTJ6bTkgMGMtMS4xNyAwLTIuMTMtLjk1LTIuMTMtMi4xMiAwLTEuMTcuOTYtMi4xMyAyLjEzLTIuMTNzMi4xMi45NiAyLjEyIDIuMTMtLjk1IDIuMTItMi4xMiAyLjEyeiIvPgogICAgPHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+Cjwvc3ZnPg==)
    center center no-repeat !important;
}
.vjs-600 .vjs-forward-control,
.vjs-600 .vjs-logo-bar,
.vjs-600 .vjs-mirror-button,
.vjs-600 .vjs-rewind-control {
  display: none;
}
.vjs-320 .vjs-chapters-button,
.vjs-320 .vjs-subs-caps-button,
.vjs-480 .vjs-audio-button,
.vjs-480 .vjs-control-text,
.vjs-480 .vjs-descriptions-button,
.vjs-480 .vjs-forward-control,
.vjs-480 .vjs-logo-bar,
.vjs-480 .vjs-mirror-button,
.vjs-480 .vjs-picture-in-picture-control,
.vjs-480 .vjs-rewind-control {
  display: none !important;
}
.vjs-480 .vjs-progress-slide,
.vjs-480 .vjs-thumbnail-holder {
  display: none;
}
.vjs-480 .vjs-chapter {
  bottom: 15px;
}
.vjs-480 .vjs-playlist-nav .vjs-next,
.vjs-480 .vjs-playlist-nav .vjs-prev {
  width: 21px;
  height: 33px;
  -webkit-background-size: 21px 33px;
  background-size: 21px 33px;
}
.vjs-480 .vjs-extend-zoom {
  display: none !important;
}
.vjs-480 .vjs-texttrack-settings {
  display: none;
}
.vjs-480 .vjs-lock-showing {
  -webkit-transform: none !important;
  transform: none !important;
}
.vjs-480 .vjs-menu .vjs-selected {
  color: #fff !important;
  background: #666 !important;
}
.vjs-480 .vjs-sharing-container .vjs-inputs-body {
  display: none !important;
}
.vjs-480 .vjs-sharing-container .vjs-share-icon {
  margin: 0 0.07em !important;
}
.vjs-480 .vjs-menu,
.vjs-480 .vjs-menu-settings {
  bottom: 5px;
  background-color: rgba(228, 228, 228, 1);
  z-index: 2;
}
.vjs-480 .vjs-menu-settings .vjs-menu-content {
  background-color: rgba(228, 228, 228, 1);
}
.vjs-480 .vjs-big-play-button,
.vjs-480 .vjs-loading-spinner {
  width: 66px !important;
  height: 66px !important;
}
.vjs-480 .vjs-loading-spinner {
  margin-top: -33px;
  margin-left: -33px;
}
.vjs-480 .vjs-big-play-button:before {
  border-width: 15px 0 15px 20px;
}
.vjs-480 .vjs-caption-settings {
  width: 90%;
  height: 90%;
}
.vjs-480 div.vast-skip-button {
  bottom: 60px !important;
}
.vjs-480 .vjs-vast-label {
  line-height: 40px !important;
  padding: 0 10px 0 0 !important;
}
.vjs-480 .vjs-marker {
  top: 0;
}
.vjs-480 .vjs-thumbnail-holder {
  bottom: 1.2em;
}
.vjs-480 .vjs-marker-inn {
  height: 4px;
}
.vjs-480 .vjs-progress-slide {
  display: none;
}
.vjs-480 .vjs-control-bar .vjs-progress-control {
  width: 100%;
  display: block;
  position: absolute;
  top: -8px;
  margin: 0;
  height: 12px;
}
.vjs-480 .vjs-progress-control .vjs-slider:before {
  height: 6px;
  top: 3px;
  background: #e0e0e0;
}
.vjs-480 .vjs-progress-control .vjs-load-progress {
  background: #fff;
  opacity: 1 !important;
}
.vjs-480 .vjs-progress-control .vjs-load-progress,
.vjs-480 .vjs-progress-control .vjs-play-progress {
  top: 3px;
  height: 6px;
}
.vjs-480 .vjs-progress-control .vjs-play-progress:before {
  display: none;
}
.vjs-480 .vjs-volume-horizontal {
  display: none !important;
  width: 0 !important;
}
.vjs-480 .vjs-spacer {
  -moz-box-flex: auto !important;
  -webkit-flex: auto !important;
  -ms-flex: auto !important;
  flex: auto !important;
  display: flex !important;
  display: -webkit-box !important;
  text-align: left !important;
}
@media print {
  .video-js > :not(.vjs-tech):not(.vjs-poster) {
    visibility: hidden;
  }
}
.js-focus-visible .video-js :focus:not(.focus-visible),
.video-js .vjs-menu :focus:not(:focus-visible),
.video-js :focus:not(:focus-visible) {
  outline: 0;
}
.vjs-label-hidden {
  display: none !important;
}
.vjs-vast-bottom {
  bottom: 60px !important;
}
.vjs-iab .vjs-control-bar {
  visibility: hidden;
  opacity: 0;
}
.vjs-ima-nonlinear .vjs-b-f-b,
.vjs-ima-nonlinear .vjs-b-p-b,
.vjs-ima-nonlinear .vjs-b-r-b,
.vjs-ima-nonlinear .vjs-brightness {
  display: none;
}
.vjs-ima-nonlinear.vjs-paused.vjs-has-started .vjs-background-bar,
.vjs-ima-nonlinear.vjs-paused.vjs-has-started .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible !important;
}
.vjs-ima-nonlinear .vjs-forward-control,
.vjs-ima-nonlinear .vjs-rewind-control {
  display: inline-block !important;
}
.vjs-ad-playing .vjs-nonlinear,
.vjs-has-started .vjs-black-poster.vjs-hidden,
.vjs-using-native-controls .vjs-black-poster,
div.vjs-vpaid-ad div.vjs-progress-control,
div.vjs-vpaid-ad div.vjs-time-controls,
div.vjs-vpaid-ad div.vjs-time-divider {
  display: none;
}
.video-js .vjs-nonlinear {
  position: absolute;
  bottom: 10px;
  max-width: 90%;
  max-height: 80%;
  background: 0 0;
  transform: translateX(-50%);
  left: 50%;
  transition: bottom 0.4s ease;
  cursor: pointer;
  text-align: center;
  z-index: 2147483647;
  overflow: hidden;
}
.video-js .vjs-nonlinear img {
  width: 100%;
  height: 100%;
  border: 0;
}
.video-js.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear,
.video-js.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall,
.video-js.vjs-user-active .vjs-nonlinear,
.video-js.vjs-user-active .vjs-nonlinear-recall {
  bottom: 60px;
}
.vjs-480.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear,
.vjs-480.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall,
.vjs-480.vjs-user-active .vjs-nonlinear,
.vjs-480.vjs-user-active .vjs-nonlinear-recall {
  bottom: 55px;
}
.vjs-1600.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear,
.vjs-1600.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall,
.vjs-1600.vjs-user-active .vjs-nonlinear,
.vjs-1600.vjs-user-active .vjs-nonlinear-recall {
  bottom: 80px;
}
.vjs-nonlinear .nonlinear-close {
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 55;
  width: 17px;
  height: 17px;
  stroke: #666;
  fill: #666;
  stroke-width: 1.25;
  cursor: pointer;
  background-color: #e5e5e5;
  border-style: outset;
  border-width: 1px;
}
.vjs-nonlinear-recall {
  position: absolute;
  right: 390px;
  width: 24px;
  height: 12px;
  background: rgba(200, 200, 200, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 0;
  bottom: 10px;
  left: 50%;
  margin-left: -12px;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.vjs-nonlinear-recall:hover {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 0;
}
.vjs-black-poster,
div.vjs-vpaid-ad.vjs-vpaid-flash-ad div.VPAID-container {
  background-color: #000;
}
.vjs-vast-label {
  font-size: 12px;
  line-height: 40px;
  color: #222;
  display: none;
  padding: 0 10px 0 5px;
}
.vjs-black-poster {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;
}
div.VPAID-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
div.vjs-vpaid-ad .vjs-tech {
  z-index: 0;
}
div.vast-skip-button {
  display: block;
  position: absolute;
  bottom: 70px;
  right: 0;
  background-color: #e5e5e5;
  color: #222;
  font-size: 13px;
  width: auto;
  padding: 8px;
  z-index: 2;
  border: 1px solid #fff;
  border-right: none;
  white-space: nowrap;
}
.vjs-1600 .vjs-button > .vjs-icon-placeholder:before,
.vjs-1600 .vjs-control-bar .vjs-chromecast-button .vjs-icon-placeholder:before,
.vjs-1600
  .vjs-control-bar
  .vjs-chromecast-button.vjs-chromecast-casting-state
  .vjs-icon-placeholder:before,
.vjs-1600 .vjs-mirror-button:before,
.vjs-1600 .vjs-mirrored:before,
.vjs-1600 .vjs-mode-control:before {
  font-size: 22px;
}
.vast-blocker,
.vjs-resize-manager {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
p.vast-skip-button-text {
  display: block;
  font-weight: 100;
  margin: 0;
  padding: 0;
}
.vast-skip-button.enabled,
.vast-skip-button.enabled:hover {
  cursor: pointer;
}
.vast-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  right: 0;
  bottom: 0;
}
.vast-skip-button.enabled:after {
  font-family: nuevo;
  content: "\e904";
  position: relative;
  margin-left: 8px;
}
.vjs-ad-playing.vjs-vast-ad-loading .vjs-loading-spinner {
  display: block;
  z-index: 10;
  -webkit-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}
.vjs-ad-playing .vjs-vast-label {
  display: inline-block;
}
.vjs-ad-playing.vjs-ad-loading .vjs-loading-spinner {
  display: block;
}
.vjs-ad-playing .vjs-play-progress:before {
  background: #f7bc5b !important;
}
.vjs-ad-playing .vjs-progress-control {
  pointer-events: none;
}
.vjs-ad-playing .vjs-load-progress {
  background-color: transparent;
}
.vjs-ad-playing .vjs-play-progress {
  background-color: #ffe400 !important;
}
.vjs-ad-playing .vjs-audio-button,
.vjs-ad-playing .vjs-chapters-button,
.vjs-ad-playing .vjs-chromecast-button,
.vjs-ad-playing .vjs-cog-menu-button,
.vjs-ad-playing .vjs-control-text,
.vjs-ad-playing .vjs-descriptions-button,
.vjs-ad-playing .vjs-download-control,
.vjs-ad-playing .vjs-forward-control,
.vjs-ad-playing .vjs-grid,
.vjs-ad-playing .vjs-info,
.vjs-ad-playing .vjs-live-control,
.vjs-ad-playing .vjs-logo,
.vjs-ad-playing .vjs-logo-bar,
.vjs-ad-playing .vjs-mirror-button,
.vjs-ad-playing .vjs-mode-control,
.vjs-ad-playing .vjs-picture-in-picture-control,
.vjs-ad-playing .vjs-playlist-button,
.vjs-ad-playing .vjs-quality-button,
.vjs-ad-playing .vjs-rewind-control,
.vjs-ad-playing .vjs-seek-to-live-control,
.vjs-ad-playing .vjs-sharing-overlay,
.vjs-ad-playing .vjs-snap-control,
.vjs-ad-playing .vjs-subs-caps-button,
.vjs-ad-playing .vjs-text-track-display,
.vjs-ad-playing .vjs-volume-horizontal,
.vjs-ad-playing .vjs-vplaylist,
.vjs-ad-playing .vjs-zoom-help,
.vjs-dai .vjs-remaining-time,
.vjs-dai .vjs-time-control,
.vjs-dai .vjs-time-divider {
  display: none !important;
}
.vjs-dai .vjs-progress-control {
  opacity: 0;
  pointer-events: none;
}
.vjs-ended .vjs-chromecast-button,
.vjs-ended .vjs-control-bar,
.vjs-ended .vjs-control-text,
.vjs-ended .vjs-playlist-button,
.vjs-ended .vjs-vplaylist {
  display: none;
}
.vjs-resize-manager {
  position: absolute;
  border: none;
  visibility: hidden;
}
.vjs-has-started.vjs-chromeless {
  pointer-events: none !important;
}
.vjs-has-started.vjs-def {
  pointer-events: auto !important;
}
.vjs-chromeless .vjs-background-bar,
.vjs-chromeless .vjs-control-bar,
.vjs-chromeless .vjs-loading-spinner {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}
.vjs-1600 .vjs-button {
  width: 40px;
}
.vjs-1600 .vjs-chapters-button .vjs-icon-placeholder:before,
.vjs-1600 .vjs-forward-control .vjs-icon-placeholder:before,
.vjs-1600 .vjs-play-control .vjs-icon-placeholder:before,
.vjs-1600 .vjs-play-control.vjs-paused .vjs-icon-placeholder:before,
.vjs-1600 .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-1600 .vjs-rewind-control .vjs-icon-placeholder:before,
.vjs-1600 .vjs-subs-caps-button .vjs-icon-placeholder:before,
.vjs-1600.vjs-descriptions-button .vjs-icon-placeholder:before {
  font-size: 24px;
}
.vjs-1600 .vjs-quality-button {
  line-height: 42px;
}
.vjs-1600 .vjs-quality-button span,
.vjs-1600 .vjs-time-control,
.vjs-1600 .vjs-vast-label,
.vjs-has-mouse .vjs-control-text {
  font-size: 14px;
  font-weight: 400;
}
.vjs-1600 .vjs-download-control .vjs-icon-placeholder:before {
  font-size: 20px;
}
.vjs-1600 .vjs-rewind-control .num {
  right: 19px;
  font-size: 9px;
  bottom: 9px;
}
.vjs-1600 .vjs-forward-control .num {
  left: 19px;
  font-size: 9px;
  bottom: 9px;
}
.vjs-1600 .vjs-live-control,
.vjs-1600 .vjs-seek-to-live-control {
  font-size: 16px;
  font-weight: 700;
}
.vjs-1600 .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 28px;
}
.vjs-1600 .vjs-logo-bar img {
  max-height: 21px;
}
